<!--  -->
<template>
  <div class="forget_pwd">
    <div>
      <video
        class="display-video"
        muted
        type="video/mp4"
        src="../../assets/video/video.mp4"
        autoplay=""
        loop=""
      ></video>
    </div>
    <div
      class="forget_form"
      :style="{ position: 'fixed', top: '0px', left: '0px' }"
    >
      <div class="forget_content">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <div class="tac title-container">
            <h3 class="title">找 回 密 码</h3>
          </div>
          <el-form-item label="手机号：" prop="phone">
            <el-input
              v-model="form.phone"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码：" prop="code">
            <el-row>
              <el-col :span="12">
                <el-input v-model="form.code" placeholder="请输入验证码">
                </el-input>
              </el-col>
              <el-col :span="12">
                <el-button  type="primary" size="mini" :disabled="!show" @click="handleCode">
                  {{ show ? "获取验证码" : `重新获取(${count}s)` }}</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="新密码：" prop="password">
            <el-input
              v-model="form.password"
              type="password"
              placeholder="请输入新登录密码"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="确认新密码：" prop="surePassword">
            <el-input
              v-model="form.surePassword"
              type="password"
              placeholder="请再次输入新登录密码"
            >
            </el-input>
          </el-form-item>
          <el-form-item class="tal">
            <el-button type="primary" @click="handleSubmit()">确 定</el-button>
            <el-button @click="handleToLogin()">取 消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="footer">
      <p>
        <span>备案号：</span>
        <a href="https://beian.miit.gov.cn/" target="_blank" class=footer-text>苏ICP备2021024718号-1</a>
        <span class=footer-text>|</span>
        <span>已通过ISO/IEC 27001:2022认证</span>
      </p>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { regexs } from '@/utils/validate'
import { forgetPwd, forgetCode } from '@/api/userApi'
import { getParameterByName } from "@/utils/index";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    const validatePwd = (rule, value, callback) => {
      if (!value) {
        callback(new Error('确认密码不能为空'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致，请重新输入'))
      }
      callback()
    }
    //这里存放数据
    return {
      show: true,
      count: '',
      timer: null,
      form: {},
      rules: {
        phone: [
          { required: true, message: '手机号码不能为空', trigger: 'submit' },
          { pattern: regexs.mobile, message: '请输入正确的手机号', trigger: 'submit' }
        ],
        code: [
          { required: true, message: '验证码不能为空', trigger: 'submit' },
          { min: 6, max: 6, message: '请输入6位验证码', trigger: 'submit' }
        ],
        password: [
          { required: true, message: '新登录密码不能为空', trigger: 'submit' },
          { pattern: regexs.pwd, message: '请输入8~15位字母和数字组成的密码', trigger: 'submit' }
        ],
        surePassword: [
          { required: true, validator: validatePwd, trigger: 'submit' }
        ],
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  //方法集合
  methods: {
    handleSubmit () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          forgetPwd(this.form).then(() => {
            this.$message.success('重置密码成功')
            //this.$router.push('/login')
            this.handleToLogin();//跳转需要带上service参数
          })
        }
      })
    },
    handleCode () {
      this.$refs.form.validateField('phone', (phoneError) => {
        //验证手机号码是否正确
        if (!phoneError) {
          const TIME_COUNT = 60
          if (!this.timer) {
            this.count = TIME_COUNT
            this.show = false
            // eslint-disable-next-line no-unused-vars
            forgetCode(this.form.phone).then((r) => {
              this.$message.success(`短信验证码发送成功!`)
              //this.$message.success(`今日已获取验证出${r.useTimes}次，还有${r.leftTimes}次机会`)
              // eslint-disable-next-line no-unused-vars
            }).catch((ex) => {
              this.$message.error(`短信验证码发送失败,请联系管理员!`);
            });
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--
              } else {
                this.show = true
                clearInterval(this.timer)
                this.timer = null
              }
            }, 1000)
          }
        } else {
          return false
        }
      })
    },
    handleToLogin () {
      //service
      let service = getParameterByName("service");
      if (service) {
        //console.log(service,'service............');
        this.$router.push({
          path: "/login",
          query: {
            service: service
          }
        });
      } else {
        this.$router.push({
          path: "/login"
        });
      }
    }
  },
}
</script>
<style lang='scss'>
//@import url(); 引入公共css类
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.forget_form .el-form-item__label {
  color: white;
}

.display-video {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.forget_pwd {
  min-height: 100%;
  width: 100%;
  //background: url('../../assets/image/login_bg.png') no-repeat center center;
  background-image: linear-gradient(to bottom right, #0e1a36, #172649);
  background-size: 100% 100%;
  max-height: 100%;
  overflow: hidden;
  & > .forget_form {
    width: 100%;
    min-height: 937px;
    text-align: right;
    padding-right: 130px;
    overflow-y: auto;
    .forget_content {
      margin: 50px;
      margin-top: 150px;
      width: 500px;
      padding: 60px 70px 120px 50px;
      display: inline-block;
      background: rgba(71, 106, 153, 0.24);
      border-radius: 5px;
      .el-form-item__content {
        .el-input__inner {
          background: #0e162b !important;
          border: 1px solid #1a2950 !important;
          color: #ffffff !important;
        }
      }
    }
  }

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }
  .el-checkbox__label {
    font-size: 12px !important;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #088eff !important;
    border-color: #088eff !important;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #088eff !important;
  }
  .tips {
    font-size: 14px;

    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  .footer {
    position: fixed;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #8194BB;
    font-size: 12px;
    
    a {
      color: #8194BB;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .footer-text {
    margin-right: 20px;
  }
}
</style>